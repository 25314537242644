import { memo, useEffect } from "react";
import {
  Path,
  RelativeRoutingType,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

export interface RedirectProps {
  to: string;
  replace?: boolean;
  relative?: RelativeRoutingType;
  preserveQuery?: boolean;
}

// Similar to react-router's Redirect component, but it can preserve the query params
export const Redirect = memo(
  ({ preserveQuery, to, replace, relative }: RedirectProps) => {
    const [params] = useSearchParams();
    const navigate = useNavigate();
    useEffect(() => {
      const path: Partial<Path> = {
        pathname: to,
        search: preserveQuery ? params.toString() : undefined,
      };

      navigate(path, { replace, relative });
    }, []);
    return null;
  },
);
