export function instantToDate(instant: Temporal.Instant): Date {
  return new Date(instant.epochMilliseconds);
}

/**
 * Add duration relative to UTC
 */
export function instantAddUtc(
  instant: Temporal.Instant,
  duration: Temporal.Duration,
) {
  return instant.toZonedDateTimeISO("UTC").add(duration).toInstant();
}

/**
 * Subtract duration relative to UTC
 */
export function instantSubtractUtc(
  instant: Temporal.Instant,
  duration: Temporal.Duration,
) {
  return instant.toZonedDateTimeISO("UTC").subtract(duration).toInstant();
}

export function dateToCurrentPlainDate(date: Date): Temporal.PlainDate {
  return date
    .toTemporalInstant()
    .toZonedDateTimeISO(Temporal.Now.timeZoneId())
    .toPlainDate();
}

export function plainDateToCurrentDate(plainDate: Temporal.PlainDate): Date {
  return zonedDateTimeToDate(
    plainDate.toZonedDateTime(Temporal.Now.timeZoneId()),
  );
}

export function zonedDateTimeToDate(
  zonedDateTime: Temporal.ZonedDateTime,
): Date {
  return new Date(zonedDateTime.epochMilliseconds);
}
