import * as classNames from "classnames";
import { ReactNode, memo } from "react";
import * as labeledInputCss from "./labeled-input.module.css";
import { Text } from "./text";

export type LabelTheme =
  | typeof LabelTheme.NORMAL
  | typeof LabelTheme.LIGHT
  | typeof LabelTheme.THIN
  | typeof LabelTheme.THIN_BOLD;

export namespace LabelTheme {
  export const NORMAL = Symbol("Normal");
  export const LIGHT = Symbol("Light");
  export const THIN = Symbol("Thin");
  export const THIN_BOLD = Symbol("Thin Bold");
}

export type LabelPosition =
  | typeof LabelPosition.ABOVE
  | typeof LabelPosition.LEFT
  | typeof LabelPosition.RIGHT;

export namespace LabelPosition {
  export const ABOVE = Symbol("above");
  export const LEFT = Symbol("left");
  export const RIGHT = Symbol("right");
}

const labelPositionClass = new Map<LabelPosition, string>();
labelPositionClass.set(LabelPosition.ABOVE, labeledInputCss.vertical);
labelPositionClass.set(LabelPosition.LEFT, labeledInputCss.horizontal);
labelPositionClass.set(LabelPosition.RIGHT, labeledInputCss.horizontal);

export const LabeledInput = memo(
  ({
    label,
    id,
    children,
    description,
    position = LabelPosition.ABOVE,
    errors = [],
    theme = LabelTheme.NORMAL,
    className,
  }: {
    id?: string;
    label: ReactNode;
    children: ReactNode;
    description?: ReactNode | ReactNode[];
    position?: LabelPosition;
    theme?: LabelTheme;
    errors?: string[];
    className?: string;
  }) => {
    return (
      <div
        className={classNames(
          labeledInputCss.container,
          labelPositionClass.get(position),
          className,
          {
            [labeledInputCss.light]: theme === LabelTheme.LIGHT,
            [labeledInputCss.thin]: theme === LabelTheme.THIN,
            [labeledInputCss.thinBold]: theme === LabelTheme.THIN_BOLD,
          },
        )}
      >
        {position === LabelPosition.RIGHT && (
          <>
            {children}
            {description && (
              <Text as="div" fontSize="sm" textColor="tertiary">
                {description}
              </Text>
            )}
            {label && (
              <label className={labeledInputCss.label} htmlFor={id}>
                {label}
              </label>
            )}
          </>
        )}
        {[LabelPosition.LEFT, LabelPosition.ABOVE].includes(position) && (
          <>
            {label && (
              <label className={labeledInputCss.label} htmlFor={id}>
                {label}
              </label>
            )}
            {children}
            {description && (
              <Text as="div" fontSize="sm" textColor="tertiary">
                {description}
              </Text>
            )}
          </>
        )}
        {errors.map((error, index) => (
          <div className={labeledInputCss.error} key={index}>
            {error}
          </div>
        ))}
      </div>
    );
  },
);
