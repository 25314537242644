export const objectEntries = Object.entries as <T>(
  object: T,
) => [keyof T, T[keyof T]][];

export function objectMapValues<A, B>(
  obj: A,
  fn: (value: A[keyof A], key: keyof A) => B,
): { [K in keyof A]: B } {
  const result = <{ [K in keyof A]: B }>{};
  for (const [key, value] of objectEntries(obj)) {
    result[key] = fn(value, key);
  }
  return result;
}
