// extracted by mini-css-extract-plugin
export var brand = "_redotech-redo-web-src-button-brand";
export var button = "_redotech-redo-web-src-button-button";
export var chevron = "_redotech-redo-web-src-button-chevron";
export var chevronButton = "_redotech-redo-web-src-button-chevron-button";
export var content = "_redotech-redo-web-src-button-content";
export var danger = "_redotech-redo-web-src-button-danger";
export var dangerOutlined = "_redotech-redo-web-src-button-danger-outlined";
export var dark = "_redotech-redo-web-src-button-dark";
export var darkTheme = "_redotech-redo-web-src-button-dark-theme";
export var extraSmall = "_redotech-redo-web-src-button-extra-small";
export var fullWidth = "_redotech-redo-web-src-button-full-width";
export var ghost = "_redotech-redo-web-src-button-ghost";
export var gray = "_redotech-redo-web-src-button-gray";
export var icon = "_redotech-redo-web-src-button-icon";
export var iconButton = "_redotech-redo-web-src-button-icon-button";
export var iconLeft = "_redotech-redo-web-src-button-icon-left";
export var iconRight = "_redotech-redo-web-src-button-icon-right";
export var large = "_redotech-redo-web-src-button-large";
export var light = "_redotech-redo-web-src-button-light";
export var medium = "_redotech-redo-web-src-button-medium";
export var micro = "_redotech-redo-web-src-button-micro";
export var nano = "_redotech-redo-web-src-button-nano";
export var outlined = "_redotech-redo-web-src-button-outlined";
export var pending = "_redotech-redo-web-src-button-pending";
export var primary = "_redotech-redo-web-src-button-primary";
export var small = "_redotech-redo-web-src-button-small";
export var solidLight = "_redotech-redo-web-src-button-solid-light";
export var solidLightBrand = "_redotech-redo-web-src-button-solid-light-brand";
export var spinner = "_redotech-redo-web-src-button-spinner";
export var transparent = "_redotech-redo-web-src-button-transparent";
export var warning = "_redotech-redo-web-src-button-warning";