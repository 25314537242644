import { memo } from "react";
import { Navbar } from "./navbar";
import * as page from "./page.module.css";

export const Page = memo(
  ({
    alwaysShowNavbar = false,
    children,
  }: {
    alwaysShowNavbar?: boolean;
    children: React.ReactElement;
  }) => {
    return (
      <div className={page.pageWrapper}>
        <Navbar alwaysShow={alwaysShowNavbar} className={page.navBar} />
        {children}
      </div>
    );
  },
);
