import {
  JsonFormat,
  objectJsonFormat,
  stringEnumJsonFormat,
  symbolJsonFormat,
  symbolUnionJsonFormat,
} from "@redotech/json/format";

/** Credit type */
export enum StoreCreditType {
  /** Discount code */
  DISCOUNT_CODE = "discount_code",
  /** Gift card */
  GIFT_CARD = "gift_card",
}

const creditTypeJsonFormat: JsonFormat<StoreCreditType> =
  stringEnumJsonFormat(StoreCreditType);

/**
 * Return method
 */
export type ReturnOptionMethod =
  | ReturnOptionMethod.StoreCredit
  | ReturnOptionMethod.Exchange
  | ReturnOptionMethod.Refund;

export namespace ReturnOptionMethod {
  export const STORE_CREDIT = Symbol("credit");
  export const EXCHANGE = Symbol("exchange");
  export const REFUND = Symbol("refund");

  /**
   * Store credit
   */
  export interface StoreCredit {
    type: typeof STORE_CREDIT;
    creditType: StoreCreditType;
  }

  export const creditJsonFormat: JsonFormat<StoreCredit> = objectJsonFormat(
    { type: symbolJsonFormat(STORE_CREDIT), creditType: creditTypeJsonFormat },
    {},
  );

  /**
   * Exchange
   */
  export interface Exchange {
    type: typeof EXCHANGE;
  }

  export const exchangeJsonFormat: JsonFormat<Exchange> = objectJsonFormat(
    { type: symbolJsonFormat(EXCHANGE) },
    {},
  );

  /**
   * Refund
   */
  export interface Refund {
    type: typeof REFUND;
  }

  export const refundJsonFormat: JsonFormat<Refund> = objectJsonFormat(
    { type: symbolJsonFormat(REFUND) },
    {},
  );
}

export const returnOptionMethodJsonFormat: JsonFormat<ReturnOptionMethod> =
  symbolUnionJsonFormat("type", "type", {
    [ReturnOptionMethod.STORE_CREDIT]: ReturnOptionMethod.creditJsonFormat,
    [ReturnOptionMethod.EXCHANGE]: ReturnOptionMethod.exchangeJsonFormat,
    [ReturnOptionMethod.REFUND]: ReturnOptionMethod.refundJsonFormat,
  });
