import TinyColor from "@ctrl/tinycolor";
import { useLoad } from "@redotech/react-util/load";
import { useStyleProperty } from "@redotech/react-util/style";
import {
  ReturnFlow,
  returnFlowJsonFormat,
} from "@redotech/redo-model/return-flow";
import type { Settings, Team } from "@redotech/redo-model/team";
import { CustomerPortalVersion } from "@redotech/redo-model/team";
import { ShopifyStorefrontClient } from "@redotech/shopify-storefront";
import { ReactNode, createContext, memo } from "react";
import { createPortal } from "react-dom";
import { getSettings } from "../api";

export interface ReturnAppSettings {
  theme: {
    button_background_color: string;
    button_text_color: string;
    button_color_dark: boolean;
    accent_color: string;
    accent_background_color: string;
    accent_text_color: string;
    home_text_color: string;
    background_image: string;
    logo_image: string;
    enable_alert: boolean;
    notification: string;
    returnButtonText: string;
    claimButtonText: string;
    standardExchangeText?: string;
    instantExchangeText: string;
    exchangeButtonText?: string;
    notExchangeButtonText?: string;
    privacy_link: string;
    body_font_family: string;
    title_font_family: string;
    custom_css: string;
    shippingFeeText: string;
    custom_confirmation_text?: string;
    green_return_text?: string;
    hideDiscountCodes: boolean;
    green_return_confirmation_text?: string;
    green_return_confirmation_description?: string;
    cantFindOrderText?: string;
    claimSummarySubtext?: string;
    returnSummarySubtext?: string;
  };
  customerEmail: string;
  merchantName: string;
  discountDistributionMethod: Settings["discountDistributionMethod"];
  returns: Settings["returns"];
  packageProtection?: Settings["packageProtection"];
  hasStorefrontAccess: boolean;
  storefrontAccessToken: string;
  locations: Settings["locations"];
  returnInStoreEnabled?: boolean;
  customerAccounts?: Settings["customerAccounts"];
  storeUrl: string;
  exchanges: Settings["exchanges"];
  inventory: Settings["inventory"];
  returnFlow: ReturnFlow;
  claimFlow: ReturnFlow;
  finalizeReturnFlow: ReturnFlow;
  finalizeClaimFlow: ReturnFlow;
  warrantyFlow: ReturnFlow;
  active: boolean;
  returnAdjustment: Settings["returnAdjustment"];
  coverage: Settings["coverage"];
  merchantCoverage: Settings["merchantCoverage"];
  returnPortalHeaderText?: string;
  returnPortalButtonText?: string;
  deductLabelFromCredit?: boolean;
  deductLabelFromRefund?: boolean;
  hidePortalBranding: boolean;
  exchangeGroups: Settings["exchangeGroups"];
  variantExchangeTitle?: string;
  exchangeGroupsExchangeTitle?: string;
  sameItemTitle?: string;
  newItemTitle?: string;
  showLabelExpirationDate: boolean;
  labelExpiration: Settings["labelExpiration"];
  bundleRulesList: Settings["bundleRulesList"];
  instantRefundEnabled?: boolean;
  differentPricedVariantExchanges: boolean;
  variantExchangeProductTags: string[];
  orderTracking: Settings["orderTracking"];
  returnTracking: Settings["returnTracking"];
  resourceOverride: Settings["resourceOverride"];
  portalExcludedProductTags?: string[];
  pickupEnabled: boolean;
  exchangeOptionText?: string;
  storeCreditOptionText?: string;
  refundOptionText?: string;
  enableNonShopifyClaims: boolean;
  nonShopifyClaimsCollection?: {
    id: string;
    name: string;
  };
  claimReviewHeader?: string;
  claimShippingLineItemText?: string;
  automation: Team["automation"];
  support: Settings["support"];
  customerPortalVersion: CustomerPortalVersion;
}

export const SettingsContext = createContext<ReturnAppSettings | undefined>(
  undefined,
);
export const StorefrontClientContext =
  createContext<ShopifyStorefrontClient | null>(null);

export const SettingsProvider = memo(
  ({ children }: { children: ReactNode | ReactNode[] }) => {
    const settingsLoad = useLoad(async () => {
      const response = await getSettings();
      const data = response.data;
      let storefrontClient: ShopifyStorefrontClient | null = null;
      if (data.storefrontAccessToken) {
        storefrontClient = new ShopifyStorefrontClient(
          data.storeUrl,
          data.storefrontAccessToken,
        );
      }
      return {
        storefrontClient,
        settings: {
          theme: {
            button_background_color: data.theme.button_color,
            button_text_color:
              TinyColor(data.theme.button_color).getBrightness() < 200
                ? "#ffffff"
                : "#000000",
            button_color_dark:
              TinyColor(data.theme.button_color).getBrightness() < 200,
            accent_color: data.theme.accent_color,
            accent_background_color: data.theme.accent_background_color,
            accent_text_color:
              TinyColor(data.theme.accent_color).getBrightness() < 200
                ? "#ffffff"
                : "#000000",
            home_text_color: data.theme.home_text_color,
            background_image: data.theme.background_url,
            logo_image: data.theme.logo_url,
            enable_alert: data.theme.enable_alert,
            notification: data.theme.notification,
            returnButtonText: data.theme.returnButtonText,
            claimButtonText: data.theme.claimButtonText,
            standardExchangeText: data.theme.standard_exchange_text,
            instantExchangeText: data.theme.instant_exchange_text,
            exchangeButtonText: data.theme.exchangeButtonText,
            notExchangeButtonText: data.theme.notExchangeButtonText,
            privacy_link: data.theme.privacy_link,
            body_font_family: data.theme.body_font_family,
            title_font_family: data.theme.title_font_family,
            custom_css: data.theme.custom_css,
            shippingFeeText: data.theme.shippingFeeText,
            custom_confirmation_text: data.theme.custom_confirmation_text,
            green_return_text: data.theme.green_return_text,
            hideDiscountCodes: data.theme.hideDiscountCodes,
            green_return_confirmation_text:
              data.theme.green_return_confirmation_text,
            green_return_confirmation_description:
              data.theme.green_return_confirmation_description,
            cantFindOrderText: data.theme.cantFindOrderText,
            claimSummarySubtext: data.theme.claimSummarySubtext,
            returnSummarySubtext: data.theme.returnSummarySubtext,
          },
          customerEmail: data.customerEmail,
          merchantName: data.name,
          discountDistributionMethod: data.settings.discountDistributionMethod,
          returns: data.settings.returns,
          packageProtection: data.settings.packageProtection,
          hasStorefrontAccess: data.hasStorefrontAccess,
          storefrontAccessToken: data.storefrontAccessToken,
          locations: data.settings.locations,
          returnInStoreEnabled: data.settings.returnInStoreEnabled,
          customerAccounts: data.settings.customerAccounts,
          storeUrl: data.storeUrl,
          exchanges: data.settings.exchanges,
          inventory: data.settings.inventory,
          returnFlow:
            data.settings.returnFlow &&
            returnFlowJsonFormat.read(data.settings.returnFlow),
          claimFlow:
            data.settings.claimFlow &&
            returnFlowJsonFormat.read(data.settings.claimFlow),
          finalizeReturnFlow:
            data.settings.finalizeReturnFlow &&
            returnFlowJsonFormat.read(data.settings.finalizeReturnFlow),
          finalizeClaimFlow:
            data.settings.finalizeClaimFlow &&
            returnFlowJsonFormat.read(data.settings.finalizeClaimFlow),
          warrantyFlow:
            data.settings.warrantyFlow &&
            returnFlowJsonFormat.read(data.settings.warrantyFlow),
          active: data.service_active,
          returnAdjustment: data.settings.returnAdjustment,
          coverage: data.settings.coverage,
          merchantCoverage: data.settings.merchantCoverage,
          returnPortalHeaderText: data.settings.returnPortalHeaderText,
          showCustomerWidgetInReturnApp:
            data.settings.support?.showCustomerWidgetInReturnApp,
          returnPortalButtonText: data.settings.returnPortalButtonText,
          deductLabelFromCredit: data.settings.deductLabelFromCredit,
          deductLabelFromRefund: data.settings.deductLabelFromRefund,
          hidePortalBranding: data.settings.hidePortalBranding,
          exchangeGroups: data.settings.exchangeGroups,
          variantExchangeTitle: data.settings.variantExchangeTitle,
          exchangeGroupsExchangeTitle:
            data.settings.exchangeGroupsExchangeTitle,
          sameItemTitle: data.settings.sameItemTitle,
          newItemTitle: data.settings.newItemTitle,
          showLabelExpirationDate: data.settings.showLabelExpirationDate,
          labelExpiration: data.settings.labelExpiration,
          bundleRulesList: data.settings.bundleRulesList,
          instantRefundEnabled: data.settings.instantRefundEnabled,
          differentPricedVariantExchanges:
            data.settings.exchanges.differentPricedVariantExchanges,
          variantExchangeProductTags:
            data.settings.exchanges.variantExchangeProductTags,
          orderTracking: data.settings.orderTracking,
          returnTracking: data.settings.returnTracking,
          resourceOverride: data.settings.resourceOverride,
          portalExcludedProductTags:
            data.settings.portalExcludedProductTags || [],
          pickupEnabled: data.settings.pickupEnabled,
          exchangeOptionText: data.theme.exchangeOptionText,
          storeCreditOptionText: data.theme.storeCreditOptionText,
          refundOptionText: data.theme.refundOptionText,
          enableNonShopifyClaims: data.settings.enableNonShopifyClaims,
          nonShopifyClaimsCollection: data.settings.nonShopifyClaimsCollection,
          claimReviewHeader: data.theme.claimReviewHeader,
          claimShippingLineItemText: data.theme.claimShippingLineItemText,
          automation: data.automation,
          support: data.settings.support,
          customerPortalVersion: data.settings.customerPortalVersion,
        },
      };
    }, []);
    const settings = settingsLoad.value?.settings;
    const storefrontClient = settingsLoad.value?.storefrontClient;

    useStyleProperty(
      document.documentElement,
      "--redo-primary-color",
      settings?.theme.button_background_color,
    );
    useStyleProperty(
      document.documentElement,
      "--redo-text-color",
      settings?.theme.button_text_color,
    );
    useStyleProperty(
      document.documentElement,
      "--redo-primary-button-color",
      settings?.theme.button_background_color,
    );
    useStyleProperty(
      document.documentElement,
      "--redo-accent-color",
      settings?.theme.accent_color,
    );
    useStyleProperty(
      document.documentElement,
      "--redo-accent-color-background",
      settings?.theme.accent_background_color,
    );
    useStyleProperty(
      document.documentElement,
      "--return-app-body-font-family",
      settings?.theme.body_font_family,
    );
    useStyleProperty(
      document.documentElement,
      "--return-app-title-font-family",
      settings?.theme.title_font_family,
    );

    if (!settings) {
      return <div>Loading</div>;
    }

    return (
      <>
        {createPortal(
          <style>{settings.theme.custom_css}</style>,
          document.body,
        )}
        <SettingsContext.Provider value={settings}>
          <StorefrontClientContext.Provider value={storefrontClient}>
            {children}
          </StorefrontClientContext.Provider>
        </SettingsContext.Provider>
      </>
    );
  },
);
