import { memo, ReactNode, useContext, useEffect, useState } from "react";
import { getWidgetId, setCustomerWidgetAuthToken } from "./api";
import { SettingsContext } from "./app/settings";
import { CustomerWidget } from "./utils/customer-widget";

export const CustomerWidgetWrapper = memo(
  ({ children }: { children: ReactNode | ReactNode[] }) => {
    const settings = useContext(SettingsContext);
    const [widgetScriptLoaded, setWidgetScriptLoaded] = useState(false);

    // Create the customer widget script widget on mount
    useEffect(() => {
      if (
        settings?.support?.showCustomerWidgetInReturnApp &&
        !widgetScriptLoaded
      ) {
        const widgetId = getWidgetId();
        const customerWidget = new CustomerWidget(widgetId);
        customerWidget.addScriptToDOM();
        void setCustomerWidgetAuthToken();
        setWidgetScriptLoaded(true);

        // Remove the script and widget from the DOM on unmount
        return () => {
          customerWidget.removeWidgetFromDOM();
          customerWidget.removeScriptFromDOM();
          setWidgetScriptLoaded(false);
        };
      }
    }, [settings?.support?.showCustomerWidgetInReturnApp]);

    return <>{children}</>;
  },
);
