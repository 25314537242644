import * as sumBy from "lodash/sumBy";
import { Order } from "./order";
import { PaymentIntentItem } from "./payment-intent";
import type { PickupLocation } from "./return-flow";
import { Settlement } from "./return-flow";
import { ReturnMethod } from "./return-method";
import { Shipment as ShippingProviderShipment } from "./shipment";
import { Address, ExchangeGroup, Team } from "./team";
import { TimelineEvent } from "./timeline";

export const NULL_ORDER_ID = "Third-party";

export interface CustomerSummary {
  first_name?: string;
  last_name?: string;
  email?: string;
  phone_number?: string;
}

export interface Fulfillment {
  status: string;
  created?: Date;
}

export interface ReturnsFacets {
  total: number;
  byStatus: { [T in Status]: number };
}

export interface NeedsActionCounts {
  returns: number;
  claims: number;
}

export enum Status {
  OPEN = "open",
  IN_TRANSIT = "in_transit",
  CANCELLED = "cancelled",
  CLOSED = "closed",
  DELIVERED = "delivered",
  EXPIRED = "expired",
  NEEDS_REVIEW = "needs_review",
  COMPLETE = "complete",
  REJECTED = "rejected",
  PENDING = "pending",
  CUSTOMER_PENDING = "customer_pending",
  FLAGGED = "flagged",
}

export type Provision = "processed" | "instant";
// FIXME This overloads the "ReturnType<T>" TS utility type
export type ReturnType = "return" | "claim";
export enum ReturnTypeEnum {
  RETURN = "return",
  CLAIM = "claim",
}
export type InstantRefundStatus =
  | "pending"
  | "payment_failed"
  | "paid"
  | "processed"
  | "expired";

export type ReturnAddress = {
  name?: string;
  address1: string;
  address2?: string;
  city: string;
  country?: string;
  country_code?: string;
  province?: string;
  province_code?: string;
  zip?: string;
  latitude?: string;
  longitude?: string;
  location?: string; // id of location in Redo for return location
  first_name?: string;
  last_name?: string;
  company?: string;
  email?: string;
  phone: string;
};

export enum ReturnTypes {
  STORE_CREDIT = "store_credit",
  REFUND = "refund",
  EXCHANGE = "exchange",
}

export type ErrorTag =
  | "label"
  | "instant-refund"
  | "pickup"
  | "in-store-return";

export interface ReturnWarning {
  code: string;
  type: ErrorTag;
  customerMessage?: string;
  systemMessage?: string;
  error?: string;
}

interface Metadata {
  key: string;
  value: string;
}

export interface Return {
  _id: string;
  createdAt: string;
  updatedAt: string;

  loopId: number;
  idempotencyKey?: string;
  metadata: Metadata[];
  status: Status;
  customer: CustomerSummary;
  fulfillment: Fulfillment;
  processed: {
    refunds: boolean;
    storeCredit: boolean;
    exchanges: boolean;
  };
  provision: Provision;
  /** The refund object from Shopify */
  refunds: any[];
  storedCredits: {
    createdAt: string;
    updatedAt: string;

    priceRuleId?: string;
    discountCodeId?: string;
    source?: "client" | "exchange" | "exchange_stock";
    products?: string;
    value?: number;
    greenReturn: boolean;
  }[];
  giftCards: {
    createdAt: string;
    updatedAt: string;

    giftCardId?: string;
    code?: string;
    source?: "client" | "exchange" | "exchange_stock";
    products?: string[];
    value?: number;
    greenReturn: boolean;
  }[];
  products: Product[];
  cancelled_products?: Product[];
  shipment?: Shipment;
  shipments: Shipment[];
  shipmentGroups?: ShipmentGroup[];
  paymentIntents?: PaymentIntentItem[];
  exchangeOrder: any[];
  exchangeShippingName?: string;
  draftOrderId?: number;
  draftOrderURL?: string;
  markedForManualReview?: boolean;
  postage_label?: string;
  form_label?: string;
  totals: Totals;
  isFlatShipping?: boolean;
  returnMethod?: ReturnMethod;
  greenReturn?: {
    discount?: {
      amount: number;
      discountCodeId?: string;
      priceRuleId?: number;
      code?: string;
    };
    refund?: {
      amount?: number;
    };
  };
  stripe?: {
    payment_token?: string;
    customer_id?: string;
  };
  labelDeductedFromCredit?: boolean;
  shippingFeeToDeduct?: number;
  timeline: TimelineEvent[];
  team: Team;
  options: {
    sendNotifications: boolean;
    waiveDraftFee: boolean;
  };
  address?: ReturnAddress;
  shipping_address?: ReturnAddress;
  newOrderAddress?: ReturnAddress;
  merchant_address?: ReturnAddress;
  freeNewOrderShipping: boolean;
  damaged: boolean;
  wrongProduct: boolean;
  shopifyReturnId?: number;
  netsuiteReturnAuthorization?: {
    id?: number;
  };
  notes?: string;
  orders: OrderSummary[];
  type: ReturnType;
  returnType: ReturnTypes[];
  /** FOR ANALYTICS ONLY - DON'T USE FOR BUSINESS LOGIC */
  analyticsTotals?: {
    orderName?: string;
    returnValueNoTaxAdjustment?: number;
    returnTax?: number;
    returnAdjustment?: number;
    returnTotalValue?: number;
    returnExchangeValue?: number;
    returnStoreCreditValue?: number;
    returnRefundValue?: number;
    containsGreenReturn?: boolean;
  };
  operations?: {
    processedBy?: string;
    processedAt?: Date;
    processedEmail?: string;
    approvedBy?: string;
    approvedAt?: Date;
    approvedEmail?: string;
    createdBy?: string;
    createdEmail?: string;
  };
  rejectType?:
    | "Return portal"
    | "Manual review"
    | "Pre-shipment"
    | "Expired"
    | "Post-shipment";
  originalReturnOptions?: {
    advancedExchangeItems: ExchangeItem[];
    new_order_taxes: string;
    discount_allocations: DiscountAllocation[];
  };
  processAt?: {
    exchange?: Date;
    storeCredit?: Date;
    refund?: Date;
  };
  sentInvoice: boolean;
  completedAt?: Date;
  // This object is not very consistent in the database
  pickup?: Record<string, any> & {
    email?: string;
    phone?: string | null;
    pickupDate: string;
    pickupLocation: {
      packageLocation: PickupLocation;
      specialInstructions: string;
    };
    surveySent?: boolean;
    pickupPayer: string;
  };
  pickupMetrics?: {
    eligible?: boolean;
    normalLabel?: number;
    rate?: number;
    coveredByRedo?: boolean;
    isMobile?: boolean;
  };
  inStoreReturn?: boolean;
  settlement?: Settlement;
  instantRefund: {
    astra_user_id: string;
    /** @deprecated // TODO Delete after astraRoutines migration */
    astraRoutine?: any;
    astraRoutines: any[];
    astraRoutinePullback?: any;
    status: InstantRefundStatus;
    sandbox: boolean;
  } | null;
  lastEmailReminder: Date | null;
  warnings: ReturnWarning[];
  advancedExchangeItems: ExchangeItem[];
  new_order_taxes: string;
  discount_allocations: DiscountAllocation[];
  usePreDiscountPrice: boolean;
  expirationDate: Date;
  filedWithCarrier: Date | null;

  // Virtuals
  some_processed: boolean;

  // Added in schema.methods.json() method
  /** _id, converted from ObjectId to string */
  id: string;
  currentEmailFlows?: {
    emailFlowId: string;
    currentStep: number;
    continueDate: string;
    fulfillmentId?: string;
  }[];
}

export interface DiscountAllocation {
  discountedAmount: {
    amount: string;
  };
}

export interface ShopifyVariant {
  sku: string;
  product_id: number;
}

export interface NewItem {
  images?: string[];
  price?: string;
  variantTitle?: string;
  variantId?: string;
  title?: string;
  shopifyVariant?: ShopifyVariant;
  quantity?: number;
  attributes?: Metadata[];
}

export interface Totals {
  refund?: number;
  storeCredit?: number;
  greenReturnCredit?: number;
  fee: number;
  returnCollectionHoldAmount?: number;
  depositRefunded?: boolean;
  charge?: number;
}

export interface Shipment {
  _shipment: ShippingProviderShipment;
  _refund?: any;
  _refundError?: any;
  toAddress: Address;
  fromAddress: Address;
  parcel: {
    length?: number;
    width?: number;
    height?: number;
    weight: number;
  };
  isReturn: boolean;
  mode: "test" | "production";
  pickup: any;
  postage_label?: string;
  form_label?: string;
  shipmentGroupID?: string;
}

export interface ShipmentGroup {
  id: string;
  address: ReturnAddress;
}

export interface OrderSummary {
  name: string;
  number?: number;
  order: string;
}

export interface Product {
  _id: string;
  exchange_for?: {
    images: string[];
    product_id: string;
    price: string;
    option1: string | null;
    option2: string | null;
    option3: string | null;
    variant_id: string;
    sku?: string;
    variant_title: string;
    product_title: string;
  };
  images: string[];
  variant_exists: boolean;
  strategy: string;
  status: string;
  quantity: number;
  grams: number;
  green_return: boolean;
  damaged: boolean;
  wrong_product: boolean;
  id: string;
  reason: string;
  condition: string;
  order: Order;
  product_id: string;
  price: string;
  price_adjustment?: string;
  merchant_adjustment?: string;
  sku: string;
  option1: string | null;
  option2: string | null;
  option3: string | null;
  variant_id: string;
  variant_title: string;
  product_title: string;
  line_item_id: string;
  notes: string;
  cancelled: boolean;
  rejectMessage?: string;
  isManualReview: boolean;
  manualReviewReason?: string;
  isFlagged: boolean;
  flaggedReason?: string;
  originalVariantOptions?: {
    strategy: string;
    exchange_for?: {
      images: string[];
      product_id: string;
      price: string;
      option1: string | null;
      option2: string | null;
      option3: string | null;
      variant_id: string;
      sku?: string;
      variant_title: string;
      product_title: string;
    };
    price_adjustment?: string;
    exchangeGroup?: ExchangeGroup;
    exchangeGroupItem?: ExchangeGroupItem;
  };
  multipleChoiceAnswers?: MultipleChoiceAnswer[];
  exchangeGroup?: ExchangeGroup;
  exchangeGroupItem?: ExchangeGroupItem;
  shipmentGroupID?: string;
  isVariantExchange: () => boolean;
}

export interface ExchangeGroupItem {
  productId: string;
  variantId: string;
  title: string;
  variantTitle: string;
  imageSrc: string;
}

export interface LineItemAnswers {
  lineItemId: string;
  answers: MultipleChoiceAnswer[];
}

export interface MultipleChoiceAnswer {
  result: string[];
  question: string;
  questionText: string;
  answer: string;
}

export interface ExchangeItem {
  variantId?: string;
  title?: string;
  variantTitle?: string;
  images?: string[];
  price?: string;
  tax?: string;
  itemValue?: string;
  quantity?: number;
  attributes?: {
    key: string;
    value: string;
  }[];

  // FIXME Not contained in the database schema
  currencyCode?: string;
}

/**
 * The merchant-server does some post-processing after retrieving the Return from the database
 * and adds more properties. This type represents the changes made.
 */
export interface MerchantAppReturn
  extends Omit<
    Return,
    "storedCredits" | "advancedExchangeItems" | "originalReturnOptions"
  > {
  // New properties for elements of existing arrays
  storedCredits: (Return["storedCredits"][number] & {
    code: string;
  })[];
  advancedExchangeItems: MerchantAppExchangeItem[];
  originalReturnOptions?: Omit<
    NonNullable<Return["originalReturnOptions"]>,
    "advancedExchangeItems"
  > & {
    advancedExchangeItems: MerchantAppExchangeItem[];
  };

  // New properties
  exchanges: any[];
  draftOrder?: any;
  customerOrders: Order[];
  customerReturns: Return[];
}

/**
 * Shorthand type - represents the changes the merchant-app makes to Return.advancedExchangeItems
 * @see MerchantAppReturn
 */
type MerchantAppExchangeItem = Return["advancedExchangeItems"][number] & {
  shopifyVariant?: ShopifyVariant;
};

/**
 * Purchase value
 */
export function returnOriginalValue(return_: Return): number {
  return sumBy(return_.products || [], (product: Product) => +product.price);
}

export function returnOriginalPrice(return_: Return): number {
  return sumBy(
    return_.products || [],
    (product: Product) =>
      +(
        product.order.shopify.line_items.find(
          (lineItem: any) =>
            String(lineItem.id) === String(product.line_item_id),
        )?.price || product.price
      ),
  );
}

export function productTax(product: Product): number {
  let taxValue = 0.0;
  const shopifyLineItem = product.order.shopify.line_items.find(
    (lineItem: any) => String(lineItem.id) === String(product.line_item_id),
  );
  const taxLines = shopifyLineItem?.tax_lines || [];
  for (const taxLine of taxLines) {
    taxValue += parseFloat(taxLine.price) / (shopifyLineItem?.quantity || 1);
  }

  return taxValue;
}

export function returnTax(return_: Return): number {
  let taxValue = 0.0;
  const products = return_.products || [];
  for (const product of products) {
    taxValue += productTax(product);
  }
  return parseFloat(taxValue.toFixed(2));
}

export function returnDiscounts(return_: Return): number {
  let discountValue = 0.0;
  const products = return_.products || [];
  for (const product of products) {
    const lineItem = product.order.shopify.line_items.find(
      (lineItem: any) => String(lineItem.id) === String(product.line_item_id),
    );
    const discountAllocations = lineItem?.discount_allocations;
    if (discountAllocations) {
      for (const allocation of discountAllocations) {
        discountValue += parseFloat(allocation.amount) / lineItem.quantity;
      }
    }
  }
  return parseFloat(discountValue.toFixed(2));
}

export function returnAdjustment(return_: Return): number {
  return sumBy(
    return_?.products || [],
    (product: Product) => +product.price_adjustment! || 0,
  );
}

export function returnExchangeValue(return_: Return): number {
  return (
    sumBy(return_.products || [], (product) =>
      product.exchange_for ? +product.price || 0 : 0,
    ) +
    sumBy(
      return_.advancedExchangeItems || [],
      (product) => +(product.price || "0"),
    )
  );
}

export function isDamaged(returnReason?: string | null): boolean {
  if (!returnReason) {
    return false;
  }
  const damaged =
    returnReason.toLowerCase().includes("damage") ||
    returnReason.toLowerCase().includes("broken") ||
    returnReason.toLowerCase().includes("defective");

  return damaged;
}

export function isWrongProduct(returnReason?: string | null): boolean {
  if (!returnReason) {
    return false;
  }
  // Some merchants differentiate between 'Purchased wrong product' and 'Received wrong product'.
  // We should not charge the merchant for 'Purchased wrong product'
  const wrongProduct =
    (returnReason.toLowerCase().includes("wrong product") ||
      returnReason.toLowerCase().includes("wrong item")) &&
    !(
      returnReason.toLowerCase().includes("purchased") ||
      returnReason.toLowerCase().includes("ordered")
    );

  return wrongProduct;
}
