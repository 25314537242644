export const setLocalStorageJson = (key: string, value: any) => {
  const item = {
    value,
  };
  try {
    const itemStr = JSON.stringify(item);
    localStorage.setItem(key, itemStr);
  } catch (error) {
    console.error(error);
  }
};

export const getLocalStorageJson = (key: string) => {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  try {
    const item = JSON.parse(itemStr);
    return item.value;
  } catch (error) {
    console.error(error);
    return null;
  }
};
