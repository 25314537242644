import { useLayoutEffect } from "react";

export function useStyleProperty(
  node: HTMLElement,
  name: string,
  value: string,
) {
  useLayoutEffect(() => {
    const oldValue = node.style.getPropertyValue(name);
    node.style.setProperty(name, value);
    return () => node.style.setProperty(name, oldValue);
  }, [node, name, value]);
}
