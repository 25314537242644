import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

export type Theme = "light" | "dark" | "system";
export const themeValues: readonly Theme[] = [
  "light",
  "dark",
  "system",
] as const;

interface ThemeContextProps {
  theme: Theme;
  setTheme: (theme: Theme) => void;
}

const ThemeContext = createContext<ThemeContextProps | undefined>(undefined);

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const osDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [theme, setTheme] = useState<Theme>(
    (localStorage.getItem("theme") as Theme) ?? "light",
  );

  useEffect(() => {
    localStorage.setItem("theme", theme);
    document.documentElement.dataset.theme =
      theme === "system"
        ? osDarkMode
          ? "dark"
          : "light"
        : theme === "dark"
          ? "dark"
          : "light";
  }, [theme, osDarkMode]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export function getNextTheme(theme: Theme) {
  const currentIndex = themeValues.indexOf(theme);
  return themeValues[(currentIndex + 1) % themeValues.length];
}
