import {
  arrayJsonFormat,
  dateJsonFormat,
  JsonFormat,
  numberJsonFormat,
  objectJsonFormat,
  stringJsonFormat,
} from "@redotech/json/format";
import {
  ICustomerProductList,
  IProductListItem,
} from "./customer-product-list-definition";

const productListItemJsonFormat: JsonFormat<IProductListItem> =
  objectJsonFormat(
    {
      productId: stringJsonFormat,
      productUrl: stringJsonFormat,
      productTitle: stringJsonFormat,
      variantId: stringJsonFormat,
      priceAmount: numberJsonFormat,
      priceCurrencyCode: stringJsonFormat,
      createdAt: dateJsonFormat,
      updatedAt: dateJsonFormat,
    },
    {
      imageSrcUrl: stringJsonFormat,
      variantTitle: stringJsonFormat,
    },
  );

export const customerProductListJsonFormat: JsonFormat<ICustomerProductList> =
  objectJsonFormat(
    {
      team: stringJsonFormat,
      email: stringJsonFormat,
      items: arrayJsonFormat(productListItemJsonFormat),
    },
    {},
  );
