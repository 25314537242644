import { useSkipAnimations } from "@redotech/react-animation/transition";
import { Input } from "@redotech/ui/form";
import * as classnames from "classnames";
import { ReactNode, memo } from "react";
import * as checkboxCss from "./checkbox.module.css";
import CheckIcon from "./icon-old/check.svg";

export enum CheckboxTheme {
  NORMAL,
  GHOST,
}

const checkboxThemeClasses = new Map<CheckboxTheme, string>();
checkboxThemeClasses.set(CheckboxTheme.NORMAL, checkboxCss.normal);
checkboxThemeClasses.set(CheckboxTheme.GHOST, checkboxCss.ghost);

export const CheckboxGroup = memo(
  ({ children }: { children: ReactNode | ReactNode[] }) => {
    return <div className={checkboxCss.checkboxGroup}>{children}</div>;
  },
);

export const Checkbox = memo(
  ({
    children,
    disabled,
    theme = CheckboxTheme.NORMAL,
    value,
    onChange,
    clickStopPropagation = false,
    id,
  }: {
    children?: ReactNode;
    disabled?: boolean;
    theme?: CheckboxTheme;
    value: boolean;
    onChange?(value: boolean): void;
    clickStopPropagation?: boolean;
    id?: string;
  }) => {
    const skipAnimations = useSkipAnimations();

    return (
      <label
        className={classnames(
          checkboxCss.checkboxButton,
          checkboxThemeClasses.get(theme),
          skipAnimations,
          { [checkboxCss.disabled]: disabled },
        )}
        id={id}
        onClick={(event) => clickStopPropagation && event.stopPropagation()}
      >
        <input
          checked={value}
          className={checkboxCss.input}
          disabled={disabled}
          onChange={(event) => onChange && onChange(event.target.checked)}
          type="checkbox"
        />
        <div className={checkboxCss.box}>
          <CheckIcon className={checkboxCss.check} />
        </div>
        {children && <div className={checkboxCss.label}>{children}</div>}
      </label>
    );
  },
);

export const FormCheckbox = memo(
  ({
    children,
    disabled,
    theme = CheckboxTheme.NORMAL,
    input,
  }: {
    children?: ReactNode;
    disabled?: boolean;
    theme?: CheckboxTheme;
    input: Input<boolean>;
  }) => {
    return (
      <Checkbox
        disabled={disabled}
        onChange={input.setValue}
        theme={theme}
        value={input.value}
      >
        {children}
      </Checkbox>
    );
  },
);
