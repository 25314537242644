import {
  AuthorizationField,
  ContentTypeField,
  credentialsFieldValueFormat,
} from "@redotech/http/semantics";
import { Json } from "@redotech/json/json";
import { MultipartType } from "@redotech/media-type/multipart";
import type { ExchangeRates } from "@redotech/money/exchange-rates";
import { bearerCredentialsFormat } from "@redotech/oauth2/request";
import { UpdateCustomerInfoData } from "@redotech/redo-model/customer";
import { ICustomerProductList } from "@redotech/redo-model/customer-product-list/customer-product-list-definition";
import { customerProductListJsonFormat } from "@redotech/redo-model/customer-product-list/customer-product-list-json-format";
import {
  EmailConsent,
  MessagingConsent,
  SMSConsent,
} from "@redotech/redo-model/messaging-consent";
import { ReturnAddress } from "@redotech/redo-model/return";
import { Address, ReturnRequest } from "@redotech/redo-model/return-flow";
import { ProductInfo } from "@redotech/redo-model/shopping-event/shopping-event-definition";
import type { GetWidgetResponse } from "@redotech/redo-model/widget";
import {
  getLocalStorageJson,
  setLocalStorageJson,
} from "@redotech/redo-web/utils/local-storage-wrapper";
import { CUSTOMER_WIDGET_TOKEN_KEY } from "@redotech/redo-web/utils/shared-conf";
import type { old_ExchangeRates } from "@redotech/server/utils/open-exchange-rates";
import { LocationData } from "@redotech/server/utils/shopify/graphql/locations";
import type { AxiosInstance } from "axios";
import axios, { AxiosHeaders, AxiosResponse } from "axios";
import { REDO_API_URL } from "./config";
import { TrackingType } from "./order/order-tracking/fulfillment";

const CUSTOMER_PORTAL_TOKEN_KEY = "redo.portal_auth_token";

function widgetClient(): AxiosInstance {
  const widgetId = getWidgetId();
  if (!widgetId) {
    throw new Error("Missing ?widget_id");
  }
  const baseURL = `${REDO_API_URL}/widgets/${widgetId}`;

  const headers = new AxiosHeaders();
  return axios.create({ baseURL, headers });
}

function client(): AxiosInstance {
  const headers = new AxiosHeaders();
  return axios.create({ baseURL: REDO_API_URL, headers });
}

const WIDGET_ID_REGEX = /^\/widget_id\/([^/]+).*/;

export function getWidgetId(): string | undefined {
  let widgetId =
    WIDGET_ID_REGEX.test(window.location.pathname) &&
    window.location.pathname.replace(WIDGET_ID_REGEX, "$1");
  if (!widgetId && widget_id !== "replace_me") {
    widgetId = widget_id;
  }
  return widgetId;
}

export async function getOrCreateCustomerWidgetAuthToken(): Promise<string> {
  await setCustomerWidgetAuthToken();
  return getLocalStorageJson(CUSTOMER_WIDGET_TOKEN_KEY);
}

export async function setCustomerWidgetAuthToken(): Promise<boolean> {
  const existingCustomerWidgetAuthToken = getLocalStorageJson(
    CUSTOMER_WIDGET_TOKEN_KEY,
  );
  if (!existingCustomerWidgetAuthToken) {
    const customerAuthToken = localStorage.getItem(CUSTOMER_PORTAL_TOKEN_KEY);
    const { token } = await createCustomerWidgetAuth(customerAuthToken);
    setLocalStorageJson(CUSTOMER_WIDGET_TOKEN_KEY, token);
    return !!token;
  }
  return false;
}

export const authentication = () => {
  const token = localStorage.getItem(CUSTOMER_PORTAL_TOKEN_KEY);
  if (!token) {
    return {};
  }
  return {
    [String(AuthorizationField.name)]: credentialsFieldValueFormat.write(
      bearerCredentialsFormat.write(token),
    ),
  };
};

export const getCustomerInfo = (customerId: string) => {
  return widgetClient().get(`customer/${customerId}`, {
    headers: authentication(),
  });
};

export const putCustomerInfo = (customer: UpdateCustomerInfoData) => {
  return widgetClient().put(`customer/${customer._id}`, {
    headers: authentication(),
    customer,
  });
};

export const getOrders = (params) => {
  return widgetClient().get("orders", { headers: authentication(), params });
};

export const getOrderDiscountByOrderId = (
  id,
  trackingType?: TrackingType,
  returnTypes?: string[],
) => {
  const trackerParam = trackingType ? `/${trackingType}` : "";
  const isRefundParam = returnTypes ? `/${returnTypes.join(",")}` : "";
  return widgetClient().get(
    `orders/${id}/discount` + trackerParam + isRefundParam,
    {
      headers: authentication(),
    },
  );
};

export const createOrderDiscountByOrderId = (
  id,
  trackingType?: TrackingType,
  returnTypes?: string[],
) => {
  const params = [];

  if (trackingType) {
    params.push(`trackingType=${trackingType}`);
  }

  if (returnTypes) {
    params.push(`returnTypes=${returnTypes.join(",")}`);
  }

  const queryString = params.length ? `/${params.join("/")}` : "";
  const url = `orders/${id}/discount${queryString}`;

  return widgetClient().post(url, {
    headers: authentication(),
  });
};

export const getOrderById = (id, includeUnfulfilled = false) => {
  return widgetClient().get(
    `orders/${id}${includeUnfulfilled ? "?includeUnfulfilled=true" : ""}`,
    { headers: authentication() },
  );
};

export const getReturn = (id) => {
  return widgetClient().get(`returns/${id}`, { headers: authentication() });
};

export const updateReturnNewOrderAddress = (id: string, address: Address) => {
  return widgetClient().put(`returns/${id}/newOrderAddress`, {
    headers: authentication(),
    params: {
      address: {
        name: address.name,
        address1: address.street1,
        address2: address.street2,
        city: address.city,
        province: address.state,
        zip: address.zip,
        country: address.country,
        phone: address.phone,
        email: address.email,
        country_code: address.country,
      },
    },
  });
};

export async function getExchangeRates(): Promise<ExchangeRates> {
  const response = await client().get<ExchangeRates>("money/exchange-rates", {
    headers: authentication(),
  });
  return response.data;
}

export async function old_getCurrencyExchangeRates(
  baseCurrency?: string,
): Promise<AxiosResponse<old_ExchangeRates>> {
  return widgetClient().get<old_ExchangeRates>("currency-conversion-rates", {
    params: { baseCurrency },
    headers: authentication(),
  });
}

export const getCarrierLocations = (
  carrier: string,
  params: { zip: string; country?: string },
) => {
  return widgetClient().get(`/carriers/${carrier}/locations`, {
    params,
    headers: authentication(),
  });
};

export type CreateAuthRequest =
  | {
      type: "order";
      email: string;
      orderNumber: string;
      isGift: boolean;
      emailOrZip: string;
    }
  | { type: "email"; token: string }
  | { type: "order_tracking"; token: string }
  | { type: "products"; productIds: string[] };

export interface CreateAuthResponse {
  token: string;
  customer: { email: string; name: string; customerId: string };
  orderID: string;
}

export interface CreateSupportAuthResponse {
  token: string;
}

/**
 * POST /widgets/:id/customer-widget-auth
 */
export const createCustomerWidgetAuth = async (
  customerAuthToken: string,
): Promise<CreateAuthResponse> => {
  const body: Json = { customerAuthToken };
  const response = await widgetClient().post("customer-widget-auth", body);
  return response.data;
};

/**
 * POST /widgets/:id/customer-auth
 */
export const createAuth = async (
  request: CreateAuthRequest,
): Promise<CreateAuthResponse> => {
  let body: Json;
  switch (request.type) {
    case "email":
    case "order_tracking":
      body = { token: request.token };
      break;
    case "order":
      body = {
        email: request.email,
        orderNumber: request.orderNumber,
        isGift: request.isGift,
        emailOrZip: request.emailOrZip,
      };
      break;
    case "products":
      body = { productIds: request.productIds };
  }
  const response = await widgetClient().post("customer-auth", body, {
    params: { type: request.type },
  });
  return response.data;
};

export interface SendAuthEmailRequest {
  email: string;
  url: string;
}

export interface SendAuthEmailResponse {}

/**
 * POST /widgets/:id/customer-auth/email
 */
export const sendAuthEmail = async (
  request: SendAuthEmailRequest,
): Promise<SendAuthEmailResponse> => {
  const response = await widgetClient().post("customer-auth/email", request);
  return response.data;
};

let settingsPromise: Promise<AxiosResponse<any, any>> | null = null;

export async function getSettings(): Promise<AxiosResponse<GetWidgetResponse>> {
  if (!settingsPromise) {
    settingsPromise = widgetClient()
      .get("")
      .catch((e) => {
        settingsPromise = null;
        throw e;
      });
  }
  return settingsPromise;
}

export const getProduct = (id: string, variantId?: string) => {
  return widgetClient().get(`products/${id}`, {
    params: { variantId },
    headers: authentication(),
  });
};

export const getProductTaxes = (params: {
  products: {
    variantId: string;
    price: string;
  }[];
  shippingAddress: ReturnAddress;
  orderId: string;
}) => {
  return widgetClient().post("products/taxes", {
    headers: authentication(),
    params,
  });
};

export const getMessagingConsent = async (
  customerId: string,
): Promise<MessagingConsent> => {
  const res = await widgetClient().get(`customer-consent/${customerId}`, {
    headers: authentication(),
  });
  return res.data;
};

export const updateMessagingConsent = async (
  customerId: string,
  {
    smsMarketingConsent,
    emailMarketingConsent,
    smsTransactionalConsent,
    emailTransactionalConsent,
  }: {
    smsMarketingConsent?: SMSConsent;
    emailMarketingConsent?: EmailConsent;
    emailTransactionalConsent?: EmailConsent;
    smsTransactionalConsent?: SMSConsent;
  },
): Promise<MessagingConsent> => {
  const body = {
    smsMarketingConsent,
    emailMarketingConsent,
    emailTransactionalConsent,
    smsTransactionalConsent,
  };

  const res = await widgetClient().post(`customer-consent/${customerId}`, {
    headers: authentication(),
    body,
  });
  return res.data;
};

export const getSubscriptionUrls = async (
  customerId: string,
): Promise<{ subscribeUrl: string; unsubscribeUrl: string }> => {
  const res = await widgetClient().get(`subscription-urls/${customerId}`, {
    headers: authentication(),
  });
  return res.data;
};

export const getShopifyOrder = (shopifyOrderId: number) => {
  return widgetClient().get(`shopify/order/${shopifyOrderId}`, {
    headers: authentication(),
  });
};

export const getProducts = () => {
  return widgetClient().get("shopify/products", { headers: authentication() });
};

export const getProductsByProductMetafield = (
  productIds: string[],
  metafieldKey: string,
) => {
  return widgetClient().get(
    `shopify/products?ids=${productIds.join()}&metafieldKey=${metafieldKey}`,
    {
      headers: authentication(),
    },
  );
};

export const getProductsByTags = (tags: string) => {
  return widgetClient().get(`shopify/products?tags=${tags}`, {
    headers: authentication(),
  });
};

export const getCollectionProducts = (collectionId: number) => {
  return widgetClient().get(`shopify/collections/${collectionId}/products`, {
    headers: authentication(),
  });
};

export const getCollectionProductsGraphql = (collectionId: number) => {
  return widgetClient().get(
    `shopify/collections/${collectionId}/products?graphql=true`,
    {
      headers: authentication(),
    },
  );
};

export const getLocations = (): Promise<{
  data: { locations: LocationData[] };
}> => {
  return widgetClient().get("shopify/locations", { headers: authentication() });
};

export const getRecommendations = (productId: number) => {
  return widgetClient().get(`shopify/recommendations/${productId}`, {
    headers: authentication(),
  });
};

export const searchProducts = (searchQuery: string) => {
  return widgetClient().get(`shopify/search/${searchQuery}`, {
    headers: authentication(),
  });
};

export const getShippingFee = (params) => {
  return widgetClient().put("returns/fee", {
    headers: authentication(),
    params,
  });
};

export const getAutomations = () => {
  return widgetClient().get("automations");
};

export const uploadImages = (params) => {
  return widgetClient().post("upload", params, {
    headers: {
      ...authentication(),
      [String(ContentTypeField.name)]: String(
        MultipartType.subtype("form-data"),
      ),
    },
  });
};

// TODO: share this with the customer-portal-app
(window as any).uploadImages = uploadImages;

export const submitReturn = (params: ReturnRequest) => {
  return widgetClient().post("returns/submit", {
    headers: authentication(),
    params,
  });
};

export const reschedulePickup = (returnId, params) => {
  return widgetClient().put(`returns/${returnId}/reschedulePickup`, {
    headers: authentication(),
    params,
  });
};

export const cancelPickup = (returnId) => {
  return widgetClient().delete(`returns/${returnId}/cancelPickup`, {
    headers: authentication(),
  });
};

export const createCart = (params) => {
  return widgetClient().post("cart/create", {
    headers: authentication(),
    params,
  });
};

export const getCart = (params) => {
  return widgetClient().post("cart/get", { headers: authentication(), params });
};

export async function addItemsToCart(params: {
  storeUrl: string;
  cartId: string;
  lines: {
    variantId: number | string;
    properties?: Record<string, any>;
    quantity?: number;
  }[];
}) {
  return widgetClient().post("cart/add", { headers: authentication(), params });
}

export async function removeItemsFromCart(params: {
  storeUrl: string;
  cartId: string;
  lineIds: string[];
}) {
  return widgetClient().post("cart/remove", {
    headers: authentication(),
    params,
  });
}

export const updateCartItemQuantity = (params: {
  storeUrl: string;
  itemId: string;
  quantity: number;
  cartId: string;
}) => {
  return widgetClient().post("cart/updateQuantity", {
    headers: authentication(),
    params,
  });
};

export const addDiscountCodesToCart = (params) => {
  return widgetClient().post("cart/discounts", {
    headers: authentication(),
    params,
  });
};

export const recreateExpiredDiscountCodes = (params: {
  codes: string[];
  storeUrl: string;
}) => {
  return widgetClient().post("recreate-discount-codes", {
    headers: authentication(),
    params,
  });
};

export const getCollectionsForProduct = (productId: string) => {
  return widgetClient().get(`/shopify/products/${productId}/collections`, {
    headers: authentication(),
  });
};

export const completeExchange = (token: string) => {
  return widgetClient().get(`/returns/completeExchange?token=${token}`);
};

export const verifyAddress = async (address: Address) => {
  const response = await widgetClient().post(
    "returns/address/verify",
    address,
    {
      headers: authentication(),
    },
  );
  return response.data;
};

export const getProductsBySkus = (skus: string[]) => {
  return widgetClient().get(`/products?skus=${skus.join(",")}`);
};

export const createAstraUserIntent = (params) => {
  return widgetClient().post("/astra-user-intent", {
    headers: authentication(),
    params,
  });
};

export const addCard = (params) => {
  return widgetClient().post("/astra-add-card", {
    headers: authentication(),
    params,
  });
};

export const isInstantRefundAllowedForReturn = (params: {
  country: string;
  isManualReview: boolean;
  isFlagged: boolean;
  orderId: string;
}) => {
  return widgetClient().post("/instant-refund/validation/return", {
    headers: authentication(),
    params,
  });
};

export const isInstantRefundAllowedForCustomer = (params: {
  country: string;
  isManualReview: boolean;
  isFlagged: boolean;
  orderId: string;
  astraUserId: string;
  amount: number;
}) => {
  return widgetClient().post("/instant-refund/validation/customer", {
    headers: authentication(),
    params,
  });
};

export const logPageClick = (params: {
  trackableId: string;
  trackableType: TrackingType;
  eventType: string;
  url: string;
  image?: string;
}) => {
  return widgetClient().post("/order-tracking/page-click", {
    headers: authentication(),
    params,
  });
};

export const logPageView = (params: {
  trackableId: string;
  trackableType: TrackingType;
  images: string[];
}) => {
  return widgetClient().post("/order-tracking/page-view", {
    headers: authentication(),
    params,
  });
};

export const getDeliveryEstimate = (params: {
  countryCode: string;
  provinceCode?: string;
  productId?: string;
}) => {
  return widgetClient().post("/order-tracking/delivery-estimate", {
    headers: authentication(),
    params,
  });
};

export const getProductEstimate = (params: {
  countryCode: string;
  provinceCode?: string;
  productId?: string;
}) => {
  console.log(params.countryCode, params.provinceCode);
  return widgetClient().post("/order-tracking/product-estimate", {
    headers: authentication(),
    params,
  });
};

/**
 * GET /widget/:id/customers/top-products-of-interest
 */
export async function getTopProductsOfInterest({
  token,
  signal,
  limit,
}: {
  token: string;
  signal?: AbortSignal;
  limit?: number;
}) {
  const response = await widgetClient().get(
    `/customers/top-products-of-interest`,
    {
      params: {
        token,
        limit,
      },
      signal,
    },
  );
  return response.data;
}

/**
 * GET /widget/:id/customers/viewed-products
 */
export async function getViewedProducts({
  token,
  signal,
  limit,
}: {
  token: string;
  signal?: AbortSignal;
  limit?: number;
}): Promise<ProductInfo[]> {
  const response = await widgetClient().get(`/customers/viewed-products`, {
    params: {
      token,
      limit,
    },
    signal,
  });
  return response.data;
}

/**
 * GET /widget/:id/customers/customer-product-list
 */
export async function getCustomerProductList({
  token,
  signal,
}: {
  token: string;
  signal?: AbortSignal;
}): Promise<ICustomerProductList | undefined> {
  const response = await widgetClient().get(
    `/customers/customer-product-list`,
    {
      params: {
        token,
      },
      signal,
    },
  );

  try {
    return customerProductListJsonFormat.read(response.data);
  } catch (error) {
    return undefined;
  }
}
