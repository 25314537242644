import { Popper } from "@mui/base/Popper";
import { useAnimateHeight } from "@redotech/react-animation/transition";
import { useSize } from "@redotech/react-util/size";
import * as classnames from "classnames";
import { ForwardedRef, ReactNode, forwardRef, memo, useState } from "react";
import { useDebounce } from "usehooks-ts";
import * as dropdownCss from "./dropdown.module.css";
import { Tooltip } from "./tooltip/tooltip";

export const Dropdown = memo(
  forwardRef(
    (
      {
        anchor,
        children,
        open,
        fitToAnchor = true,
        flip = true,
        placement = undefined,
        darkMode = false,
      }: {
        anchor: HTMLElement | null;
        children: ReactNode | ReactNode[];
        open: boolean;
        fitToAnchor?: boolean;
        flip?: boolean;
        placement?: string;
        darkMode?: boolean;
      },
      ref: ForwardedRef<HTMLDivElement>,
    ) => {
      const [dropdown, setDropdown] = useState<HTMLElement | null>(null);

      const [animateStyles, animating] = useAnimateHeight(dropdown);

      const debounceOpen = useDebounce(open, 1);

      const size = useSize(anchor);

      const width = fitToAnchor ? size && `${size.width}px` : undefined;

      return (
        <Popper
          anchorEl={anchor}
          modifiers={[{ name: "flip", enabled: flip }]}
          open={open || debounceOpen || animating}
          placement={placement as any} // Avoid cursed type check error when trying to import the PopupPlacement type
          ref={ref}
        >
          <div
            className={classnames(dropdownCss.dropdown, {
              [dropdownCss.dark]: darkMode,
              [dropdownCss.leave]: !open || !debounceOpen,
              [dropdownCss.animating]: animating,
            })}
            ref={setDropdown}
            style={{ ...animateStyles, width }}
          >
            {(animating || (open && debounceOpen)) && children}
          </div>
        </Popper>
      );
    },
  ),
);

export const DropdownOption = memo<{
  action?: () => void;
  children: ReactNode | ReactNode[];
  fontFamily?: string;
  disabled?: boolean;
  stopPropagation?: boolean;
  darkMode?: boolean;
  tooltip?: string;
}>(
  ({
    action,
    children,
    fontFamily,
    disabled,
    stopPropagation,
    darkMode,
    tooltip,
  }) => {
    return tooltip ? (
      <Tooltip arrow title={tooltip}>
        <button
          className={classnames(dropdownCss.option, {
            [dropdownCss.dark]: darkMode,
          })}
          disabled={disabled}
          onClick={(event) => {
            stopPropagation && event.stopPropagation();
            action && action();
          }}
          style={{ fontFamily: fontFamily }}
          type="button"
        >
          {children}
        </button>
      </Tooltip>
    ) : (
      <button
        className={classnames(dropdownCss.option, {
          [dropdownCss.dark]: darkMode,
        })}
        disabled={disabled}
        onClick={(event) => {
          stopPropagation && event.stopPropagation();
          action && action();
        }}
        style={{ fontFamily: fontFamily }}
        type="button"
      >
        {children}
      </button>
    );
  },
);
