// These need to be imported first so we can override their styles without !important
import "react-calendar/dist/Calendar.css";
import "react-date-picker/dist/DatePicker.css";

import { memo } from "react";
import DatePicker from "react-date-picker";
import * as datePickerCss from "./date-range.module.css";

export type MaybeDate = Date | null;
export type DateRangeValue = MaybeDate | [MaybeDate, MaybeDate];

export const DateInput = memo(
  ({
    value,
    valueChange,
    className,
    maxDate,
    minDate,
    disabled = false,
    minDetail = "century",
    includeYearNav = true,
    filterDate = () => true,
    fullWidth = false,
    label = "",
    error = false,
  }: {
    value: MaybeDate;
    valueChange(value: MaybeDate): void;
    maxDate?: Date;
    minDate?: Date;
    className?: string;
    disabled?: boolean;
    minDetail?: "month" | "year" | "decade" | "century";
    includeYearNav?: boolean;
    disableTyping?: boolean;
    filterDate?: ({ date }: { date: Date }) => boolean;
    fullWidth?: boolean;
    label?: string;
    error?: boolean;
  }) => {
    return (
      <div
        className={`${fullWidth ? datePickerCss.fullWidth : ""} ${className}`}
      >
        {label && (
          <label
            className={`${datePickerCss.dateLabel} ${
              error ? datePickerCss.dateLabelError : ""
            }`}
          >
            {label}
          </label>
        )}
        <DatePicker
          calendarType="gregory"
          className={`${error ? "error" : ""} ${
            fullWidth ? datePickerCss.fullWidth : ""
          }`}
          dayPlaceholder="DD"
          disabled={disabled}
          maxDate={maxDate}
          minDate={minDate}
          minDetail={minDetail}
          monthPlaceholder="MM"
          next2Label={includeYearNav ? ">>" : null}
          onChange={(value: DateRangeValue) =>
            !Array.isArray(value) && valueChange(value)
          }
          prev2Label={includeYearNav ? "<<" : null}
          showFixedNumberOfWeeks
          tileDisabled={({ date }: { date: Date }) => !filterDate({ date })}
          value={value || null}
          yearPlaceholder="YYYY"
        />
      </div>
    );
  },
);
