import { memo } from "react";
import { Outlet } from "react-router-dom";
import { AuthProvider } from "./app/auth";
import { SettingsProvider } from "./app/settings";
import { UserContextProvider } from "./app/user";
import { AdvancedExchangeContextProvider } from "./contexts/AdvancedExchangeContext";
import { BackHandlerContextProvider } from "./contexts/BackHandlerContext";
import { CurrentItemBeingReturnedContextProvider } from "./contexts/CurrentItemBeingReturnedContext";
import { CustomerContextProvider } from "./contexts/CustomerContext";
import { DiscountNameContextProvider } from "./contexts/DiscountNameContext";
import { ExchangeTypeContextProvider } from "./contexts/ExchangeTypeContext";
import { FlowStepContextProvider } from "./contexts/FlowStepContext";
import { InStoreReturnContextProvider } from "./contexts/InStoreReturnContext";
import { ItemsReadyForReturnContextProvider } from "./contexts/ItemsReadyForReturnContext";
import { PickupContextProvider } from "./contexts/PickupContext";
import { SettlementContextProvider } from "./contexts/SettlementContext";
import { ShippingContextProvider } from "./contexts/ShippingContext";

export const Root = memo(() => {
  return (
    <SettingsProvider>
      <AuthProvider>
        <BackHandlerContextProvider>
          <UserContextProvider>
            <CustomerContextProvider>
              <AdvancedExchangeContextProvider>
                <PickupContextProvider>
                  <InStoreReturnContextProvider>
                    <SettlementContextProvider>
                      <CurrentItemBeingReturnedContextProvider>
                        <ItemsReadyForReturnContextProvider>
                          <DiscountNameContextProvider>
                            <ShippingContextProvider>
                              <ExchangeTypeContextProvider>
                                <FlowStepContextProvider>
                                  <Outlet />
                                </FlowStepContextProvider>
                              </ExchangeTypeContextProvider>
                            </ShippingContextProvider>
                          </DiscountNameContextProvider>
                        </ItemsReadyForReturnContextProvider>
                      </CurrentItemBeingReturnedContextProvider>
                    </SettlementContextProvider>
                  </InStoreReturnContextProvider>
                </PickupContextProvider>
              </AdvancedExchangeContextProvider>
            </CustomerContextProvider>
          </UserContextProvider>
        </BackHandlerContextProvider>
      </AuthProvider>
    </SettingsProvider>
  );
});
