import MuiToolTip, {
  TooltipProps,
  tooltipClasses,
} from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import * as tooltipStyles from "./tooltip.module.css";
interface RedoTooltipProps extends TooltipProps {
  subtitle?: string;
}
export const Tooltip: React.FunctionComponent<RedoTooltipProps> = styled(
  ({ className, ...props }: RedoTooltipProps) => (
    <MuiToolTip
      {...props}
      classes={{ popper: className }}
      title={
        <div>
          <div>{props.title}</div>
          {props.subtitle && (
            <div className={tooltipStyles.subtitle}>{props.subtitle}</div>
          )}
        </div>
      }
    />
  ),
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#0F0F0F",
    color: "#FFFFFF",
    fontSize: "var(--redo-body-xsmall-text-size) !important",
    font: "var(--redo-body-font-family)",
    padding: "8px 12px",
    borderRadius: "var(--redo-corner-small-radius)",
    lineHeight: "18px",
  },
  [`& .${tooltipClasses.arrow}`]: {
    "&::before": {
      backgroundColor: "#0F0F0F",
    },
  },
}));
