import {
  arrayJsonFormat,
  JsonFormat,
  objectJsonFormat,
  stringJsonFormat,
  symbolJsonFormat,
  symbolUnionJsonFormat,
} from "@redotech/json/format";
import { Attachment } from "../createconversationbody";

export type Action_ = Action.SetStatus | Action.AddTag | Action.AutoReply;

export namespace Action {
  export const SET_STATUS = Symbol("set_status");
  export const ADD_TAG = Symbol("add_tag");
  export const AUTO_REPLY = Symbol("auto_reply");

  export enum SetStatusActionStatus {
    OPEN = "open",
    CLOSED = "closed",
    ARCHIVED = "archived",
    SPAM = "spam",
    SNOOZED = "snoozed",
  }
  export enum SetStatusSnoozePeriod {
    TODAY = "today",
    TOMORROW = "tomorrow",
    WEEKEND = "weekend",
    NEXT_WEEK = "nextWeek",
  }

  export interface SetStatus {
    type: typeof SET_STATUS;
    status: SetStatusActionStatus;
    snoozePeriod?: SetStatusSnoozePeriod;
  }

  export const setStatusJsonFormat: JsonFormat<SetStatus> = objectJsonFormat(
    {
      type: symbolJsonFormat(SET_STATUS),
      status: stringJsonFormat as JsonFormat<SetStatusActionStatus>,
    },
    {
      snoozePeriod: stringJsonFormat as JsonFormat<SetStatusSnoozePeriod>,
    },
  );

  export interface AddTag {
    type: typeof ADD_TAG;
    tag: string;
  }

  export const addTagJsonFormat: JsonFormat<AddTag> = objectJsonFormat(
    {
      type: symbolJsonFormat(ADD_TAG),
      tag: stringJsonFormat,
    },
    {},
  );

  export interface AutoReply {
    type: typeof AUTO_REPLY;
    textContent: string;
    htmlContent: string;
    attachments?: Attachment[];
  }

  const attachmentJsonFormat: JsonFormat<Attachment> = objectJsonFormat(
    {
      url: stringJsonFormat,
      description: stringJsonFormat,
      mimeType: stringJsonFormat,
    },
    {},
  );

  export const autoReplyJsonFormat: JsonFormat<AutoReply> = objectJsonFormat(
    {
      type: symbolJsonFormat(AUTO_REPLY),
      textContent: stringJsonFormat,
      htmlContent: stringJsonFormat,
    },
    {
      attachments: arrayJsonFormat(attachmentJsonFormat),
    },
  );
}

export type Action = Action.SetStatus | Action.AddTag | Action.AutoReply;

export const actionJsonFormat: JsonFormat<Action> = symbolUnionJsonFormat(
  "type",
  "type",
  {
    [Action.SET_STATUS]: Action.setStatusJsonFormat,
    [Action.ADD_TAG]: Action.addTagJsonFormat,
    [Action.AUTO_REPLY]: Action.autoReplyJsonFormat,
  },
);
