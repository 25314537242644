import { Player } from "@lottiefiles/react-lottie-player";
import * as classNames from "classnames";
import { memo } from "react";
import spinnerDarkJson from "./lottie/spinner-dark.json?url";
import spinnerLightJson from "./lottie/spinner-light.json?url";
import * as spinnerCss from "./spinner.module.css";

export type SpinnerStyle =
  | typeof SpinnerStyle.DARK
  | typeof SpinnerStyle.LIGHT
  | typeof SpinnerStyle.AUTO;

export namespace SpinnerStyle {
  export const DARK = Symbol("dark");
  export const LIGHT = Symbol("light");
  export const AUTO = Symbol("auto");
}

export const Spinner = memo(
  ({ style = SpinnerStyle.AUTO }: { style?: SpinnerStyle }) => {
    return (
      <span>
        <Player
          autoplay
          className={classNames(
            spinnerCss.spinner,
            style === SpinnerStyle.AUTO ? spinnerCss.auto : undefined,
          )}
          loop
          src={
            style === SpinnerStyle.LIGHT ? spinnerLightJson : spinnerDarkJson
          }
          style={{ width: "100%" }}
        />
      </span>
    );
  },
);
