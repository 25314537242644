import * as amplitude from "@amplitude/analytics-browser";
import { Outlet, RouteObject } from "react-router-dom";
import { AuthGuard } from "../app/auth";
import { Page } from "./page";

export const orderRoutes: RouteObject[] = [
  {
    handle: { breadcrumb: "Home" },
    element: (
      <AuthGuard>
        <Outlet />
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        path: "",
        lazy: async () => {
          const { Orders } = await import("./orders");
          return {
            element: (
              <Page>
                <Orders />
              </Page>
            ),
          };
        },
      },
      {
        path: ":id",
        lazy: async () => {
          const { Order } = await import("./order");
          return { Component: Order };
        },
        children: [
          {
            handle: { breadcrumb: "Order Details" },
            path: "",
            lazy: async () => {
              const { OrderDetails } = await import("./order-details");
              return { Component: OrderDetails };
            },
            children: [
              {
                handle: { breadcrumb: "Tracking" },
                path: "track/:fulfillmentIndex",
                lazy: async () => {
                  const { Fulfillment } = await import(
                    "./order-tracking/fulfillment"
                  );
                  return { Component: Fulfillment };
                },
              },
            ],
          },
          {
            path: ":returnType",
            lazy: async () => {
              const { ReturnPortal } = await import("./return-portal");
              return { Component: ReturnPortal };
            },
          },
          {
            path: "exchange",
            lazy: async () => {
              const { AdvancedExchangeWrapper } = await import(
                "./return-flow/card-contents/advanced-exchange-wrapper"
              );
              return { Component: AdvancedExchangeWrapper };
            },
          },
        ],
      },
      {
        path: "return/:id",
        lazy: async () => {
          const { ReturnStatus } = await import("./return-status");
          amplitude.logEvent("render-return-status", {
            location: "route",
          });
          return {
            element: (
              <Page>
                <ReturnStatus />
              </Page>
            ),
          };
        },
      },
    ],
  },
];
