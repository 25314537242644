import { Input } from "@redotech/ui/form";
import * as classnames from "classnames";
import type { DOMAttributes } from "react";
import {
  ForwardedRef,
  HTMLInputTypeAttribute,
  ReactNode,
  forwardRef,
  memo,
  useId,
  useState,
} from "react";
import PlusIcon from "./icon-old/plus.svg";
import TrashIcon from "./icon-old/trash.svg";
import { InputSize } from "./input";
import { LabelTheme, LabeledInput } from "./labeled-input";
import * as textInputCss from "./text-input.module.css";

export type InputTheme =
  | typeof InputTheme.ACCENT
  | typeof InputTheme.FORM
  | typeof InputTheme.WIDGET;

export namespace InputTheme {
  export const ACCENT = Symbol("accent");
  export const FORM = Symbol("form");
  export const WIDGET = Symbol("widget");
}

const inputThemeClass = new Map<InputTheme, string>();
inputThemeClass.set(InputTheme.ACCENT, textInputCss.accent);
inputThemeClass.set(InputTheme.FORM, textInputCss.form);
inputThemeClass.set(InputTheme.WIDGET, textInputCss.widget);

export enum InputLines {
  SINGLE,
  MULTI,
}

const inputSizeClass = new Map<InputSize, string>();
inputSizeClass.set(InputSize.SMALL, textInputCss.small);
inputSizeClass.set(InputSize.MEDIUM, textInputCss.medium);

export enum InputBoxHeight {
  SHORT,
  MEDIUM,
  TALL,
}

const inputBoxHeightClass = new Map<InputBoxHeight, string>();
inputBoxHeightClass.set(InputBoxHeight.SHORT, textInputCss.shortHeight);
inputBoxHeightClass.set(InputBoxHeight.MEDIUM, textInputCss.mediumHeight);
inputBoxHeightClass.set(InputBoxHeight.TALL, textInputCss.tallHeight);

export interface TextInputProps {
  id?: string;
  error?: boolean;
  placeholder?: string;
  lines?: InputLines;
  icon?: ReactNode;
  autocomplete?: string;
  readonly?: boolean;
  size?: InputSize;
  suffix?: ReactNode;
  prefix?: ReactNode;
  theme?: InputTheme;
  type?: HTMLInputTypeAttribute;
  value: string;
  min?: number;
  max?: number;
  maxLength?: number;
  onChange?(value: string): void;
  onFocus?(value: boolean): void;
  onClick?: DOMAttributes<HTMLTextAreaElement | HTMLInputElement>["onClick"];
  onSelect?: DOMAttributes<HTMLTextAreaElement | HTMLInputElement>["onSelect"];
  onKeyDown?: DOMAttributes<
    HTMLTextAreaElement | HTMLInputElement
  >["onKeyDown"];
  onKeyUp?: DOMAttributes<HTMLTextAreaElement | HTMLInputElement>["onKeyUp"];
  fullwidth?: boolean;
  step?: number | string;
  autoFocus?: boolean;
  textAreaHeight?: InputBoxHeight;
  button?: ReactNode;
  smallText?: boolean;
  disabled?: boolean;
  showBorder?: boolean;
  verticalResizeOnly?: boolean;
  allowInvalidNumbers?: boolean;
}

// FIXME Won't let the input be empty if type="number", which makes it hard for the user to type
export const TextInput = memo(
  forwardRef(
    (
      {
        error = false,
        id,
        lines = InputLines.SINGLE,
        suffix,
        size = InputSize.MEDIUM,
        placeholder,
        theme = InputTheme.WIDGET,
        autocomplete,
        prefix,
        readonly = false,
        icon,
        min,
        max,
        maxLength,
        value,
        type,
        onFocus,
        onChange,
        onKeyDown,
        onKeyUp,
        onClick,
        onSelect,
        fullwidth = false,
        step = 1,
        autoFocus = false,
        textAreaHeight = InputBoxHeight.SHORT,
        button,
        smallText = false,
        disabled = false,
        showBorder = true,
        verticalResizeOnly = false,
        allowInvalidNumbers = false,
      }: TextInputProps,
      ref: ForwardedRef<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      const onContentChange = (
        event:
          | React.ChangeEvent<HTMLInputElement>
          | React.ChangeEvent<HTMLTextAreaElement>,
      ) => {
        if (type === "number") {
          const newVal = parseFloat(event.target.value);
          const isInvalidNumber =
            isNaN(newVal) ||
            (min !== undefined && newVal < min) ||
            (max !== undefined && newVal > max);

          if (!allowInvalidNumbers && isInvalidNumber) {
            return;
          }
        }
        onChange && onChange(event.target.value);
      };
      return (
        <label
          className={classnames(
            textInputCss.textInput,
            inputThemeClass.get(theme),
            inputSizeClass.get(size),
            { [textInputCss.error]: error },
            { [textInputCss.noBorder]: !showBorder },
            { [textInputCss.disabled]: disabled },
          )}
          style={{
            flex: fullwidth ? 1 : undefined,
            width: fullwidth ? "100%" : undefined,
          }}
        >
          {icon && <div className={textInputCss.icon}>{icon}</div>}
          {prefix}
          {lines === InputLines.SINGLE ? (
            <input
              autoComplete={autocomplete}
              autoFocus={autoFocus}
              className={
                smallText
                  ? classnames(textInputCss.input, textInputCss.small)
                  : textInputCss.input
              }
              disabled={disabled}
              id={id}
              max={max}
              maxLength={maxLength}
              min={min}
              onBlur={onFocus && (() => onFocus(false))}
              onChange={onContentChange}
              onFocus={onFocus && (() => onFocus(true))}
              onKeyDown={onKeyDown}
              onKeyUp={onKeyUp}
              placeholder={placeholder}
              readOnly={readonly}
              ref={ref as ForwardedRef<HTMLInputElement>}
              step={type === "number" ? step : undefined}
              style={{ textAlign: suffix ? "right" : undefined }}
              type={type}
              value={value}
            />
          ) : (
            <textarea
              className={classnames(
                textInputCss.input,
                inputBoxHeightClass.get(textAreaHeight),
                { [textInputCss.vertical]: verticalResizeOnly },
              )}
              disabled={disabled}
              maxLength={maxLength}
              onBlur={onFocus && (() => onFocus(false))}
              onChange={onContentChange}
              onClick={onClick}
              onFocus={onFocus && (() => onFocus(true))}
              onKeyDown={onKeyDown}
              onKeyUp={onKeyUp}
              onSelect={onSelect}
              placeholder={placeholder}
              readOnly={readonly}
              ref={ref as ForwardedRef<HTMLTextAreaElement>}
              value={value}
            />
          )}
          {suffix}
          {button}
        </label>
      );
    },
  ),
);

export const FormTextInput = memo(
  ({
    description,
    label,
    input,
    labelTheme,
    lines,
    errorOveride = false,
    labeledInputClassName,
    ...props
  }: {
    description?: ReactNode;
    labelTheme?: LabelTheme;
    label: ReactNode;
    input: Input<string>;
    errorOveride?: boolean;
    labeledInputClassName?: string;
    lines?: number;
  } & Omit<
    TextInputProps,
    "error" | "id" | "value" | "onChange" | "onFocus"
  >) => {
    // Start out pretending the user is focusing on the field,
    // so errors only show after the user has interacted and then left the field.
    const [focus, setFocus] = useState(true);
    const id = useId();
    return (
      <LabeledInput
        className={labeledInputClassName}
        description={description}
        errors={!focus ? input.errors : []}
        id={id}
        label={label}
        theme={labelTheme}
      >
        <TextInput
          error={errorOveride ? true : !focus && !!input.errors.length}
          id={id}
          lines={lines}
          onChange={input.setValue}
          onFocus={setFocus}
          value={input.value}
          {...props}
        />
      </LabeledInput>
    );
  },
);

export const FormTextListInput = memo(
  ({
    description,
    label,
    input,
    labelTheme,
    lines,
    readonly = false,
    ...props
  }: {
    description?: ReactNode;
    labelTheme?: LabelTheme;
    label: ReactNode;
    input: Input<string[]>;
    readonly?: boolean;
  } & Omit<
    TextInputProps,
    "error" | "id" | "value" | "onChange" | "onFocus"
  >) => {
    const [focus, setFocus] = useState(false);
    const id = useId();

    const handleChange = (index: number, value: string) => {
      const newValue = [...input.value];
      newValue[index] = value;
      input.setValue(newValue);
    };

    const handleDelete = (index: number) => {
      const newValue = [...input.value];
      newValue.splice(index, 1);
      input.setValue(newValue);
    };

    const handleAdd = () => {
      const newValue = [...input.value];
      newValue.push("");
      input.setValue(newValue);
    };

    return (
      <LabeledInput
        description={description}
        errors={input.changed && !focus ? input.errors : []}
        id={id}
        label={label}
        theme={labelTheme}
      >
        <>
          {input.value.map((value, index) => (
            <div className={textInputCss.listItem} key={index}>
              <div>{index + 1}.</div>
              <TextInput
                error={input.changed && !focus && !!input.errors.length}
                fullwidth
                id={id}
                lines={lines}
                onChange={(e) => handleChange(index, e)}
                onFocus={setFocus}
                readonly={readonly}
                value={value}
                {...props}
              />
              {!readonly && (
                <div
                  className={textInputCss.deleteIcon}
                  onClick={() => handleDelete(index)}
                >
                  <TrashIcon />
                </div>
              )}
            </div>
          ))}
          {!readonly && (
            <div className={textInputCss.add} onClick={handleAdd}>
              <div className={textInputCss.addIcon}>
                <PlusIcon />
              </div>
              Add
            </div>
          )}
        </>
      </LabeledInput>
    );
  },
);
