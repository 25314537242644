import { Currency, isCurrency } from "@redotech/money/currencies";
import { useLoad } from "@redotech/react-util/load";
import { OrderWithReturnableItems } from "@redotech/redo-model/order";
import { ReturnType } from "@redotech/redo-model/return";
import {
  CURRENCY_FORMAT,
  CurrencyContext,
  DEFAULT_CURRENCY,
} from "@redotech/redo-web/currency";
import { LoadingRedoAnimation } from "@redotech/redo-web/loading-redo-animation";
import { createContext, memo, useContext, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { getOrderById, old_getCurrencyExchangeRates } from "../api";
import { SettingsContext } from "../app/settings";
import { StorefrontCartContextProvider } from "../contexts/StorefrontCartContext";
import { getReturnableItems, makeOrder } from "../utils/utils";
import { Page } from "./page";

export const OrderContext = createContext<OrderWithReturnableItems>(undefined);

export const Order = memo(() => {
  const { returnType } = useParams() as {
    returnType: ReturnType;
  };

  const [currency, setCurrency] = useState<Currency>(DEFAULT_CURRENCY);

  const { id } = useParams();

  const settings = useContext(SettingsContext);

  // Loads the order (only happens once on page load)
  const orderLoad = useLoad(async () => {
    const res = await getOrderById(id, true);
    const order = await makeOrder(res.data?.order, settings);

    let usdRatesPromise;

    const shopCurrencyStr: string | undefined = order?.shopify?.currency;
    const orderCurrencyStr: string | undefined =
      order?.shopify?.presentment_currency;

    const shopCurrency =
      shopCurrencyStr && isCurrency(shopCurrencyStr)
        ? shopCurrencyStr
        : DEFAULT_CURRENCY;
    const orderPresentmentCurrency =
      orderCurrencyStr && isCurrency(orderCurrencyStr)
        ? orderCurrencyStr
        : DEFAULT_CURRENCY;

    if (orderPresentmentCurrency !== Currency.USD) {
      usdRatesPromise = old_getCurrencyExchangeRates(Currency.USD);
    }

    let formatCurrency: (amount: number) => string;
    if (orderPresentmentCurrency !== shopCurrency) {
      try {
        const exchangeRates = (await old_getCurrencyExchangeRates(shopCurrency))
          .data;
        formatCurrency = exchangeRates
          ? (amount) => {
              return CURRENCY_FORMAT(orderPresentmentCurrency).format(
                amount * exchangeRates[orderPresentmentCurrency],
              );
            }
          : undefined;
      } catch (e) {
        console.error(e);
      }
    }
    if (formatCurrency === undefined) {
      formatCurrency = (amount) => CURRENCY_FORMAT(shopCurrency).format(amount);
    }

    const usdRates = usdRatesPromise ? (await usdRatesPromise).data : undefined;
    const formatCurrencyFromUSD = usdRates
      ? (amount) => {
          return CURRENCY_FORMAT(orderPresentmentCurrency).format(
            amount * usdRates[orderPresentmentCurrency],
          );
        }
      : (amount) => CURRENCY_FORMAT(Currency.USD).format(amount);

    return {
      order,
      orderCurrency: orderPresentmentCurrency,
      formatCurrency,
      formatCurrencyFromUSD,
    };
  }, []);

  // Loads the returnable items and adds them to the order. Reloads anytime the return type (i.e. claim or return) changes.
  //  For bundles the returnable items can look slightly different based on return type so we need to reload them.
  const returnableOrderLoad = useLoad(async () => {
    if (!orderLoad.value) {
      return;
    }
    const { order } = orderLoad.value;
    const returnableItems = await getReturnableItems(
      order,
      settings,
      settings.bundleRulesList,
      returnType,
    );
    order.returnableItems = returnableItems;
    return order;
  }, [orderLoad.value, returnType]);

  orderLoad.error && console.error(orderLoad.error);

  if (!orderLoad.pending && orderLoad.error) {
    return (
      <Page>
        <div>There was an error loading this order</div>
      </Page>
    );
  }

  if (
    orderLoad.pending ||
    returnableOrderLoad.pending ||
    !returnableOrderLoad.value
  ) {
    return (
      <Page>
        <LoadingRedoAnimation />
      </Page>
    );
  }

  const { order, orderCurrency, formatCurrency, formatCurrencyFromUSD } =
    orderLoad.value;
  if (currency !== orderCurrency) {
    setCurrency(orderCurrency);
  }

  return (
    <OrderContext.Provider value={returnableOrderLoad.value}>
      <CurrencyContext.Provider
        value={{
          currency,
          formatCurrency,
          setCurrency,
          formatCurrencyFromUSD,
        }}
      >
        <StorefrontCartContextProvider>
          <Page>
            <Outlet />
          </Page>
        </StorefrontCartContextProvider>
      </CurrencyContext.Provider>
    </OrderContext.Provider>
  );
});
