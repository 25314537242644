export interface Equal<T> {
  (a: T, b: T): boolean;
}

export function identityEqual<T>(a: T, b: T) {
  return Object.is(a, b);
}

export function arrayEqual<T>(element: Equal<T>): Equal<readonly T[]> {
  return (a, b) =>
    a.length === b.length && a.every((e, index) => element(e, b[index]));
}

export const booleanEqual: Equal<boolean> = identityEqual;

export const dateEqual: Equal<Date> = (a, b) => a.getTime() === b.getTime();

export function objectEqual<T>(properties: {
  [K in keyof T]: Equal<T[K]>;
}): Equal<T> {
  const p = <[keyof T, Equal<any>][]>Object.entries(properties);
  return (a, b) => p.every(([key, equal]) => equal(a[key], b[key]));
}

export function optionalEqual<T>(inner: Equal<T>): Equal<T | undefined> {
  return (a, b) =>
    a === undefined ? b === undefined : b !== undefined && inner(a, b);
}

export function mapEqual<K, V>(valueEqual: Equal<V>): Equal<Map<K, V>> {
  return (a, b) => {
    if (a.size !== b.size) {
      return false;
    }
    for (const [key, value] of a) {
      if (!b.has(key) || !valueEqual(value, b.get(key)!)) {
        return false;
      }
    }
    return true;
  };
}

export function nullableEqual<T>(inner: Equal<T>): Equal<T | null> {
  return (a, b) => (a === null ? b === null : b !== null && inner(a, b));
}

export const numberEqual: Equal<number> = identityEqual;

export const stringCaseInsensitiveEqual: Equal<string> = (a, b) =>
  !a.localeCompare(b, undefined, { sensitivity: "accent" });

export const symbolEqual: Equal<symbol> = identityEqual;

export const stringEqual: Equal<string> = identityEqual;

export const urlEqual: Equal<URL> = (a, b) => String(a) === String(b);
