import { RouteObject } from "react-router-dom";

export const thirdPartyWarrantiesRoutes: RouteObject[] = [
  {
    lazy: async () => {
      const { Page } = await import("../login/page");

      return {
        element: <Page />,
      };
    },
    children: [
      {
        index: true,
        path: "",
        lazy: async () => {
          const { Page } = await import("../order/page");
          const { ThirdPartyWarranty } = await import(
            "../order/third-party-warranty"
          );
          return {
            element: (
              <Page alwaysShowNavbar>
                <ThirdPartyWarranty />
              </Page>
            ),
          };
        },
      },
      {
        path: ":id",
        lazy: async () => {
          const { ReturnStatus } = await import("../order/return-status");
          const { Page } = await import("../order/page");
          return {
            element: (
              <Page>
                <ReturnStatus />
              </Page>
            ),
          };
        },
      },
    ],
  },
];
