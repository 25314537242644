import {
  ClientResponse,
  ReturnData,
  StorefrontApiClient,
  StorefrontOperations,
  createStorefrontApiClient,
} from "@shopify/storefront-api-client";

export class ShopifyStorefrontClient {
  private client: StorefrontApiClient;
  private tries: number = 3;

  constructor(storeUrl: string, publicAccessToken: string) {
    this.client = createStorefrontApiClient({
      storeDomain: `https://${storeUrl}`,
      apiVersion: "2024-04",
      publicAccessToken,
      retries: 3,
      customFetchApi: window.fetch,
    });
  }

  public async request<T, V extends Record<string, any>>(
    operation: string,
    variables?: V,
  ): Promise<
    ClientResponse<
      T extends undefined ? ReturnData<string, StorefrontOperations> : T
    >
  > {
    return await this.client.request<T>(operation, {
      variables,
      retries: this.tries,
    });
  }
}
