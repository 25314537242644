import * as classNames from "classnames";
import { CSSProperties, DetailedHTMLProps, ReactNode, memo } from "react";
import { StyleProps, styleClasses } from "./styles";

type TextElement = "p" | "span" | "div";

interface TextProps extends StyleProps {
  overflow?: CSSProperties["overflow"];
  textOverflow?: CSSProperties["textOverflow"];
}

export const Text = memo(
  (
    props: Omit<TextProps, "fontFamily"> & {
      children: ReactNode;
      as?: TextElement;
      fontFamily?: "inherit";
    } & DetailedHTMLProps<
        React.HTMLAttributes<HTMLSpanElement>,
        HTMLSpanElement
      >,
  ) => {
    const As: TextElement = props.as ?? "p";
    return (
      <As
        className={classNames(
          styleClasses({
            ...props,
            fontFamily: props.fontFamily ?? "inherit",
          }),
        )}
        onClick={props.onClick}
        style={{
          textOverflow: props.textOverflow,
          overflow: props.overflow,
          color: props.color,
        }}
      >
        {props.children}
      </As>
    );
  },
);

type HeaderElement = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "div";

export const Header = memo(
  (
    props: Omit<TextProps, "fontFamily"> & {
      children: ReactNode;
      as?: HeaderElement;
      fontFamily?: "inherit";
    },
  ) => {
    const As: HeaderElement = props.as ?? "h1";
    return (
      <As
        className={classNames(
          styleClasses({
            ...props,
            fontFamily: props.fontFamily ?? "header",
            textColor: props.textColor ?? "primary",
            isHeader: true,
          }),
        )}
        style={{ textOverflow: props.textOverflow, overflow: props.overflow }}
      >
        {props.children}
      </As>
    );
  },
);
